<!--
 * @Author: lbh
 * @Date: 2021-09-23 18:44:14
 * @LastEditors: lbh
 * @LastEditTime: 2022-03-24 15:19:18
 * @Description: 網上付款服務 payme for  business (快將推出)
-->
<template>
  <div
    class="t-body business-box"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      :type="window.type"
      ad="business"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 'm-body_1'"
    >
      <img
        v-for="item in 15"
        :key="item"
        :src="getIndex(item)"
      />
    </div>
    <selfFooter :type="window.type" />
    <self-contact />
  </div>
</template>

<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import util from "@/js/utils/util";
export default {
  components: {
    selfHeaderN,
    selfFooter,
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        swiperH: 0,
        introduce_class: '',
        restaurant_class: '',
        w: 0
      },
    }
  },
  mounted () {
    this.initWindow();
    util.setTitle('餐飲系統, 餐飲POS, QRcode點餐, QR Code點餐, QR 點餐, 掃碼點餐, 手機點餐, 點餐系統, 落單系統, 客人自助點餐, 手提落單, 點菜系統, 無線落單, 會員系統, 雲端點餐, 手機報表, Membership, 飯糰, Ricepon, 外賣系統, POS餐飲系统, 餐廳系統, 平板POS, Tablet POS');
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.swiperH = parseInt(w / 2.744);
      this.window.w = w;
      if (w < 700) {
        this.window.type = '600';
        this.window.introduce_class = 'introduce-app_2'
        this.window.restaurant_class = 'restaurant-case_2'
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.introduce_class = "introduce-app_1"
        this.window.restaurant_class = 'restaurant-case_1'
      } else {
        this.window.type = '1000';
        this.window.introduce_class = '';
        this.window.restaurant_class = ''
      }
    },
    getImgPath (img) {
      return util.getImgPath(img);
    },
    getIndex (index) {
      if (index < 10) index = `00${index}`;
      else if (index < 100) index = `0${index}`;
      return this.getImgPath(`PayMe${index}.jpg`);
    }
  }

}
</script>

<style lang="less" scoped>
.business-box {
  .s-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    img {
      width: 80%;
      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }
  }
}
</style>